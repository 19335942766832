import React from 'react';
import CardPostComponent from '../components/CardPostComponent';
import { ThemeProvider } from '../context/themeContext';

export function RenderPostCards({ data, configuration, anon }) {
  let values = data;
  return (
    <div className="socs_newsfeed card-view">
      <div className="card_wrapper" data-cols={configuration.limit}>
        <ThemeProvider value={configuration}>
          {values.map((post, key) => (
            <CardPostComponent data={post} key={key} anon={anon} />
          ))}
        </ThemeProvider>
      </div>
    </div>
  );
}
