import React from 'react';
import Thumbnail from '../Thumbnail/Thumbnail';

export default function GalleryRenderer({ images }) {
  return (
    <>
      {images && (
        <div className="gallery">
          {images.map((img, key) => (
            <Thumbnail key={key} source={img.thumb_sm} type={img.type} />
          ))}
        </div>
      )}
    </>
  );
}
