import React, { useContext } from 'react';
import { RenderEvent } from '../../utils';
import Author from '../Author/AuthorWidget';
import ThemeContext from '../../context/themeContext';

const Modal = props => {
  const theme = useContext(ThemeContext);
  const { event, anon } = props;
  return (
    <div
      className="modal show calendar-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: 'block' }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{ backgroundColor: theme.color.hex }}
          >
            <h5 className="modal-title" style={{ color: '#ffffff' }}>
              {event.title}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => props.hideModal()}
              style={{ color: '#ffffff' }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              <RenderEvent event={event} />
            </p>
            {event.location && (
              <p>
                <strong>Locatie:</strong> {event.location}
              </p>
            )}
            {event.body && (
              <p>
                <strong>Omschrijving:</strong> {event.body}
              </p>
            )}
            <Author author={event.author} date_created={event.created} anon={anon} ></Author>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
