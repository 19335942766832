import * as React from 'react';
import {createRoot} from "react-dom/client";
import * as serviceWorker from './serviceWorker';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';

let code;

if (import.meta.env.VITE_APP_CODE) {
    code = import.meta.env.VITE_APP_CODE;
} else {
    code = window.location.pathname.slice(1);
}
const params = new URLSearchParams(window.location.search);
let anon_param = params.get("anon");
let anon;
if (anon_param === null || anon_param !== "true") {
    anon = false;
} else {
    if (anon_param === "true") {
        anon = true
    }
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement)

root.render(<App code={code} anon={anon}/>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
