import React from 'react';
import './groups.css';
import ThemeContext from '../../context/themeContext';

class GroupWidget extends React.PureComponent {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      groupsToShow: 3,
      showAll: false,
    };
  }

  toggleRemaining = () => {
    let numGroups = this.state.showAll ? 3 : this.props.lengroup;
    this.setState({ showAll: !this.state.showAll, groupsToShow: numGroups });
  };

  render() {
    let { groups, lengroup } = this.props;
    if (!groups || groups.length === 0) {
      return null;
    }
    let btnText = this.state.showAll
      ? 'verbergen'
      : `en nog ${lengroup - 3} anderen`;
    return (
      <div className="groups-tags">
        {groups.slice(0, this.state.groupsToShow).map((group, key) => (
          <span key={key} className="group-tag">
            {group.title}
          </span>
        ))}
        {lengroup > 3 && (
          <span
            class="more-tag"
            onClick={this.toggleRemaining}
            style={{ color: this.context.color.hex }}
          >
            {btnText}
          </span>
        )}
      </div>
    );
  }
}

export default GroupWidget;
