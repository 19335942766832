import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import GroupWidget from './Groups/GroupWidget';
import AuthorWidget from './Author/AuthorWidget';
import CardTitle from './Card/CardTitle';
import Card from './Card/Card';
import hasMedia from '../utils/hasmedia';
import './Card/horizontalList.css';
import {CreateMarkup} from "../utils/createMarkup";

export default function CardPostComponent({ data, anon }) {
  const Cardheader = () =>
    hasMedia(data.media) ? (
      <div className="card__header">
        <img src={data.media[0].fullPath} alt={data.title} loading="lazy" />
      </div>
    ) : (
      <div className="card__header header__icon">
        <FontAwesomeIcon icon={faNewspaper} size="6x" color="#b5bfca" />
      </div>
    );

  const cardTextMarkUp = CreateMarkup(data.body);
  return (
    <div className="card__container">
      <Card cardClass="card--vertical">
        <Cardheader></Cardheader>
        <div className="card-body">
          <GroupWidget groups={data.groups} />
          <CardTitle postUrl={data.url} title={data.title} />
          <div className="card-text" dangerouslySetInnerHTML={cardTextMarkUp} />
          <AuthorWidget author={data.author} date_created={data.created} anon={anon} />
        </div>
      </Card>
    </div>
  );
}
