import React, { useState, useEffect } from 'react';
import useNewsfeed from './hooks/useNewsfeed';
import RenderWidgets from './RenderWidgets';
import Loading from './components/Loading/Loading';
import {
  MESSAGES_ALL,
} from './constants';
import './App.css';

// Meaning of data returned in the configuration
// public enum WidgetType
//     {
//         Undefined = 0,
//         Newsfeed = 1,
//         Agendafeed = 2
//     }

// public enum WidgetDesign
//     {
//         Undefined = 0,
//         List = 1,
//         Cards = 2,
//         Expanded = 3,
//         Calendar = 4
//     }

function App({ code, anon }) {
  const [offset, setOffset] = useState(0);
  const [data, loading] = useNewsfeed(code, offset);
  const { configuration, values } = loading ? {} : data;
  const updatePosts = page => {
    setOffset(page);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    let container = document.querySelector(".socs_newsfeed, .socs_eventfeed");
    
    if (container !== null) {
      let heightAdjustment = 1;
      if (configuration.limit !== MESSAGES_ALL) {
        heightAdjustment += 46;
      }
      window.parent.postMessage(JSON.stringify({socs_code: code, socs_height:  container.offsetHeight + heightAdjustment + "px"}),'*');
    } else {
      // check for calendar widget
      let container = document.querySelector(".rbc-calendar");
      if (container !== null) {
        window.parent.postMessage(JSON.stringify({socs_code: code, socs_height:  660 + "px"}),'*');
      }
    }
  });

  return (
    <>
      {loading ? (
        <Loading text={'Laden...'} />
      ) : (
        <RenderWidgets
          data={values}
          configuration={configuration}
          updatePosts={updatePosts}
          totalPosts={data.totalCount}
          anon={anon}
        />
      )}
    </>
  );
}

export default App;
