import React, { useContext } from 'react';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import ThemeContext from '../../context/themeContext';
import './author.css';

const AuthorWidget = ({ author, date_created, anon }) => {
  const theme = useContext(ThemeContext);
  const authorName = author === null ? 'Verwijderde gebruiker' : author.name;
  const Avatar = () =>
    author === null ? (
      <FontAwesomeIcon icon={faUser} size="lg" />
    ) : (
      <img src={author.avatarUrl} alt={authorName} />
    );
  return (
    <div className="author">
      {
        !anon && (
          <div className="author__avatar">
            <Avatar />
          </div>
        )
      }
      <div className="author__meta">
        {
          !anon && (
            <>
            door <span style={{ color: theme.color.hex }}>{authorName} </span>
            </>
          )
        }
        op{' '}
        <span style={{ color: theme.color.hex }}>
          <Moment format="DD-MM-YYYY" locale="nl">
            {date_created}
          </Moment>
        </span>
      </div>
    </div>
  );
};

export default AuthorWidget;
