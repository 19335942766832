import React from 'react';
import FullPostComponent from '../components/FullPostComponent';
import { ThemeProvider } from '../context/themeContext';

export function RenderFullPosts({ data, configuration, anon }) {
  let values = data;
  return (
    <div className="socs_newsfeed full-view">
      <ThemeProvider value={configuration}>
        {values.map((post, key) => (
          <FullPostComponent data={post} key={key} anon={anon} />
        ))}
      </ThemeProvider>
    </div>
  );
}
