const hasMedia = mediaArray => {
  let media = false;
  if (typeof mediaArray !== 'undefined' && mediaArray.length > 0) {
    if (mediaArray[0].thumb_sm !== '') {
      media = true;
    }
  }
  return media;
};

export default hasMedia;
