import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import './thumbnail.css';

const Thumbnail = ({ source, alt, type }) => {
  let video = type === 2 ? true : false;
  return (
    <div className="post-thumbnail">
      {video && <FontAwesomeIcon icon={faPlayCircle} size="3x" />}
      <img alt={alt} src={source} className="" loading="lazy" />
    </div>
  );
};

export default Thumbnail;
