//  constants for designs
export const DESIGN_UNDEFINED = 0;
export const DESIGN_LIST  = 1;
export const DESIGN_CARD  = 2;
export const DESIGN_FULL  = 3;
export const DESIGN_CALENDAR  = 4;


// constants for widget type
export const TYPE_UNDEFINED = 0
export const TYPE_NEWSFEED = 1
export const TYPE_AGENDAFEED = 2

// constants for widget messages
// One = 1,
// Two = 2,
// Three = 3,
// Four = 4,
// All = 12
export const MESSAGES_ONE = 1
export const MESSAGES_TWO = 2
export const MESSAGES_THREE = 3
export const MESSAGES_FOUR = 4
export const MESSAGES_ALL = 12
