import showdown from 'showdown';
export const Converter = new showdown.Converter({
  simplifiedAutoLink: true,
  simpleLineBreaks: true,
  excludeTrailingPunctuationFromURLs: true,
  encodeEmails: false,
  openLinksInNewWindow: true,
});
export function EncodeHtmlEntities(text) {
  const entities = {
    '<': '&lt;',
    '>': '&gt;',
  };
  const re = new RegExp(`[${Object.keys(entities).join('')}]`, 'g');
  return text.replace(re, (m) => entities[m]);
}

export function CreateMarkup(description) {
  const text = EncodeHtmlEntities(description);
  const html = Converter.makeHtml(text);
  const userlinks = html.replace(/<a /g, '<a target="_blank" rel="noreferrer noopener" ');
  return { __html: userlinks };
}
