import React, { useContext } from 'react';
import ThemeContext from '../../context/themeContext';

const CardTitle = ({ postUrl, title }) => {
  const theme = useContext(ThemeContext);
  return (
    <a
      href={postUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="card-title"
      style={{ color: theme.color.hex }}
    >
      <h5>{title}</h5>
    </a>
  );
};

export default CardTitle;
