import React from 'react';
import { ThemeProvider } from '../context/themeContext';
import ListPostComponent from '../components/ListPostComponent';

export function RenderPostList({ data, configuration, anon }) {
  let values = data;

  return (
    <div className="socs_newsfeed list-view">
      <ThemeProvider value={configuration}>
        {values.map((post, key) => (
          <ListPostComponent data={post} key={key} anon={anon} />
        ))}
      </ThemeProvider>
    </div>
  );
}
