import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/nl';
import { addMinutes, addDays, endOfDay } from 'date-fns';
import ThemeContext from '../../context/themeContext';
import './Calendar.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './react-big-calendar.css';
import Modal from '../Modal/Modal';
import CustomToolbar from './CustomToolbar';

class Calendar extends PureComponent {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.anon = props.anon;
    this.state = {
      selectedEvent: {},
      display: false,
    };
  }

  componentDidMount() {
    this.setTheme();
    document.addEventListener('keyup', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.escFunction, false);
  }

  escFunction = event => {
    if (event.key === 'Escape') {
      this.hideModal();
    }
  };

  selectEvent = event => {
    let state = this.state;
    this.setState({ ...state, selectedEvent: event, display: true });
  };

  deselectEvent = () => {
    let state = this.state;
    this.setState({ ...state, selectedEvent: {} });
  };

  hideModal = () => {
    let state = this.state;
    this.setState({ ...state, display: false });
  };

  setTheme = () => {
    const theme = this.context;
    let headerElements = document.querySelectorAll('.rbc-header');
    let eventelements = document.querySelectorAll('.rbc-event');
    eventelements.forEach(el => {
      el.style.backgroundColor = theme.color.hex;
    });
    headerElements.forEach(el => {
      el.style.backgroundColor = theme.color.hex;
    });
  };

  getDate(key) {
    return obj => {
      let result = obj[key];
      result = obj.allDay ? addMinutes(obj[key], 1) : obj[key];
      return result;
    };
  }

  getTitle = event => event.title;

  render() {
    let components = {
      toolbar: CustomToolbar,
    };
    const localizer = momentLocalizer(moment);
    const messages = {
      allDay: 'de gehele dag',
      previous: '←',
      next: '→',
      today: 'Vandaag',
      month: 'maand',
      work_week: 'Werkweek',
      week: 'week',
      day: 'dag',
      agenda: 'agenda',
    };

    return (
      <div style={{ fontFamily: this.context.font }}>
        <BigCalendar
          {...this.props}
          localizer={localizer}
          onSelectEvent={this.selectEvent}
          popup={true}
          messages={messages}
          components={components}
          titleAccessor={this.getTitle}
          startAccessor={this.getDate('start')}
          endAccessor={this.getDate('end')}
          eventPropGetter={event => ({
            style: {
              backgroundColor: this.context.color.hex,
            },
          })}
        />
        {this.state.display && (
          <Modal
            event={this.state.selectedEvent}
            display={this.state.display}
            hideModal={this.hideModal}
            anon={this.anon}
          ></Modal>
        )}
      </div>
    );
  }
}

Calendar.propTypes = {
  events: PropTypes.array,
  anon: PropTypes.bool,
};

export default Calendar;
