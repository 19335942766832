import React from 'react';
import GroupWidget from './Groups/GroupWidget';
import AuthorWidget from './Author/AuthorWidget';
import Card from './Card/Card';
import CardTitle from './Card/CardTitle';
import Thumbnail from './Thumbnail/Thumbnail';
import textTruncate from '../utils/textTruncate';
import hasMedia from '../utils/hasmedia';
import {CreateMarkup} from "../utils/createMarkup";
import createDOMPurify from 'dompurify';

export default function ListPostComponent({ data, anon }) {
  const DOMPurify = createDOMPurify();
  const cardTextMarkup = CreateMarkup(data.body);
  let cardTextMarkupHtml = cardTextMarkup.__html;
  let startsWithP = cardTextMarkupHtml.startsWith('<p>') ? true : false;
  let purifiedCardText = DOMPurify.sanitize(cardTextMarkupHtml);
  let cardTextInWords = purifiedCardText.split(" ");
  let limitedCardText = cardTextInWords.length > 37 ? cardTextInWords.slice(0, 37).join(" ") + "..." : cardTextInWords.join(" ");


  return (
    <Card cardClass="card--horizontal">
      <div className="card-body">
        <GroupWidget groups={data.groups} lengroup={data.groups.length} />
        <CardTitle postUrl={data.url} title={data.title} />
        { startsWithP ?
          <div className="card-text" dangerouslySetInnerHTML={{__html: limitedCardText}} /> :
          <p className="card-text">
            {textTruncate(data.body, 150)}
          </p>
        }
        <AuthorWidget author={data.author} date_created={data.created} anon={anon} />
      </div>
      {hasMedia(data.media) && (
        <Thumbnail
          source={data.media[0].thumb_sm}
          alt={data.title}
          type={data.media[0].type}
        />
      )}
    </Card>
  );
}
