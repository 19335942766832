import React from 'react';
import './error.css';

const Errorcontainer = () => (
  <div className="container error-container">
    <div className="alert alert-danger" role="alert">
      Oeps! Er is iets misgegaan met je nieuwsfeed code, controleer de code
      nogmaals of genereer deze opnieuw{' '}
      <a
        className="alert-link"
        href="https://admin.socialschools.eu/WebsiteWidgets"
        target="_blank"
        rel="noopener noreferrer"
      >
        hier
      </a>
      .
    </div>
  </div>
);

export default Errorcontainer;
