import React from 'react';
import GroupWidget from './Groups/GroupWidget';
import AuthorWidget from './Author/AuthorWidget';
import Card from './Card/Card';
import CardTitle from './Card/CardTitle';
import { MediaRenderer } from './Media';
import {CreateMarkup} from "../utils/createMarkup";

export default function FullPostComponent({ data, anon }) {
  let description = CreateMarkup(data.body);

  return (
    <Card cardClass="card--horizontal">
      <div className="card-body">
        <GroupWidget groups={data.groups} />
        <CardTitle postUrl={data.url} title={data.title} />
        <div dangerouslySetInnerHTML={description}></div>
        <AuthorWidget author={data.author} date_created={data.created} anon={anon} />
        <MediaRenderer media={data.media} />
      </div>
    </Card>
  );
}
