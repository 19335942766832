import React from 'react';
import GalleryRenderer from './GalleryRenderer';
import FileRenderer from './FileRenderer';

export default function MediaRenderer({ media }) {
  // media might contain a lot of different types, better and cleaner to filter them here
  let images = media.filter(mediac => mediac.type === 1 || mediac.type === 2);
  let documents = media.filter(mediac => mediac.type === 3);
  return (
    <>
      {images && <GalleryRenderer images={images} />}
      {documents && <FileRenderer documents={documents} />}
    </>
  );
}
