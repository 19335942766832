import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import ThemeContext from '../../context/themeContext';
import './CustomToolbar.css';

/* CustomToolbar
This components override the default toolbar provided by the calendar
*/

const CustomToolbar = props => {
  let navigation = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
  };

  const handleChange = event => {
    // console.log(event.target.value);
    props.onView(event.target.value);
  };
  // check this for the sample implementation for this https://github.com/intljusticemission/react-big-calendar/blob/master/src/Toolbar.js#L18
  const navigate = action => {
    console.log('action', action);
    props.onNavigate(action);
  };

  const { view, views, label, localizer } = props;
  const theme = useContext(ThemeContext);
  return (
    <div className="row">
      <div className="rbc-toolbar col-md-4 rbc-toolbar-buttons">
        <span className="rbc-btn-group">
          <button
            type="button"
            value={'PREV'}
            // onClick={this.navigate.bind(null, navigate.PREVIOUS)}
            onClick={() => navigate(navigation.PREVIOUS)}
            style={{ backgroundColor: theme.color.hex }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            type="button"
            value={'TODAY'}
            // onClick={this.navigate.bind(null, navigate.TODAY)}
            onClick={() => navigate(navigation.TODAY)}
            style={{ backgroundColor: theme.color.hex }}
          >
            Vandaag
          </button>
          <button
            type="button"
            value={'NEXT'}
            // onClick={this.navigate.bind(null, navigate.NEXT)}
            onClick={() => navigate(navigation.NEXT)}
            style={{ backgroundColor: theme.color.hex }}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </span>
      </div>
      <div className="col-md-4 rbc-toolbar-title">
        <h2>
          <span
            className="rbc-toolbar-label"
            style={{ color: theme.color.hex }}
          >
            {label}
          </span>
        </h2>
      </div>
      <div className="col-md-4 rbc-toolbar-select">
        <select
          value={view}
          onChange={e => {
            handleChange(e);
          }}
          className="custom-select"
          style={{ backgroundColor: theme.color.hex }}
        >
          {views.map((value, key) => (
            <option value={value} key={key}>
              {localizer.messages[value]}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default CustomToolbar;
