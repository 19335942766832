import React, { useContext } from 'react';
import ThemeContext from '../../context/themeContext';
import './card.css';

const Card = ({ cardClass, children }) => {
  const theme = useContext(ThemeContext);
  return (
    <div className={`card ${cardClass}`} style={{ fontFamily: theme.font }}>
      {children}
    </div>
  );
};

export default Card;
