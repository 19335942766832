import React from 'react';
import { ThemeProvider } from '../context/themeContext';
import ListEventComponent from '../components/ListEventComponent';
import { fromUTCStrings } from 'socs-event-times';

export function RenderEventList({ data, configuration, anon }) {
  let values = data.map(fromUTCStrings);
  return (
    <div className="socs_eventfeed list-view">
      <ThemeProvider value={configuration}>
        {values.map((event, key) => (
          <ListEventComponent data={event} key={key} anon={anon} />
        ))}
      </ThemeProvider>
    </div>
  );
}
