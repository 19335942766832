import React from 'react';
import './loading.css';

const Loading = ({ text }) => {
  return (
    <div className="loading-wrapper">
      <div className="spinner-grow" role="status">
        <span className="sr-only">{text}</span>
      </div>
      <span className="loading-text">{text}</span>
    </div>
  );
};

export default Loading;
