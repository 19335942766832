import React from 'react';
import GroupWidget from './Groups/GroupWidget';
import AuthorWidget from './Author/AuthorWidget';
import Card from './Card/Card';
import CardTitle from './Card/CardTitle';
import { RenderEvent } from '../utils';
import DateContainer from './Date/Date';

export default function ListEventComponent({ data, anon }) {
  return (
    <Card cardClass="list-view card--horizontal">
      <div className="card-body">
        <GroupWidget groups={data.groups} />
        <CardTitle postUrl={data.url} title={data.title} />

        <RenderEvent event={data} />
        <AuthorWidget author={data.author} date_created={data.created} anon={anon} />
      </div>
      <DateContainer eventDate={data.start} />
    </Card>
  );
}
