import React, { useContext } from 'react';
import Moment from 'react-moment';
import ThemeContext from '../../context/themeContext';
import './date.css';

const DateContainer = ({ eventDate }) => {
  const theme = useContext(ThemeContext);
  return (
    <div className="dateContainer">
      <div
        className="month-wrapper"
        style={{ backgroundColor: theme.color.hex }}
      >
        <Moment format="MMM" locale="nl">
          {eventDate}
        </Moment>
      </div>
      <div className="day-wrapper">
        <Moment element="span" className="date" format="DD" locale="nl">
          {eventDate}
        </Moment>
        <Moment element="span" className="day" format="dddd" locale="nl">
          {eventDate}
        </Moment>
      </div>
    </div>
  );
};

export default DateContainer;
