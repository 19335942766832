import React from 'react';
import { ThemeProvider } from '../context/themeContext';
import { fromUTCStrings } from 'socs-event-times';
import Calendar from '../components/Calendar/Calendar';

export function RenderCalendar({ data, configuration, anon }) {
  let values = data.map(fromUTCStrings);
  return (
    <ThemeProvider value={configuration}>
      <Calendar events={values} anon={anon} />
    </ThemeProvider>
  );
}
