import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import ThemeContext from '../../context/themeContext';
import './FileRenderer.css';

export default function FileRenderer({ documents }) {
  const theme = useContext(ThemeContext);
  const style = {
    color: theme.color.hex,
  };

  return (
    <>
      {documents && (
        <div className="fileContainer">
          {documents.map((doc, key) => (
            <span className="btn btn-file" key={key} style={style}>
              <FontAwesomeIcon icon={faFile} /> {doc.fileName}
            </span>
          ))}
        </div>
      )}
    </>
  );
}
