import axios from 'axios';
// The API implementation is going to very generic and will have no knowledge whatsoever for the business logic. It's job is simple, take the URL and return data.

const GetDataFromAPI = async (URL, params = {}) => {
  return await axios
    .get(URL, {
      params,
    })
    .then(res => {
      if (res.status === 200) {
        return res.data;
      } else {
        return { results: [] };
      }
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      return {configuration: null, timeStamp: "", totalCount: 0, values: []}
    });
};

export { GetDataFromAPI };
