import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import './pagination.css';

function Pagination({ updatePosts, totalPosts, limit }) {
  const [page, setPage] = useState(0);
  let offset = limit;
  let maxPage = Math.floor(totalPosts / limit);

  useEffect(() => {
    let postOffset = page * offset;
    updatePosts(postOffset);
  });

  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };
  return (
    <div className="pagination-wrapper">
      <button
        onClick={prevPage}
        disabled={page === 0}
        className="btn feed-navigation"
      >
        <FontAwesomeIcon icon={faChevronLeft} size="lg" />
      </button>
      <button
        onClick={nextPage}
        disabled={page >= maxPage}
        className="btn feed-navigation"
      >
        <FontAwesomeIcon icon={faChevronRight} size="lg" />
      </button>
    </div>
  );
}

export default Pagination;
