import { useState, useEffect } from 'react';
import { GetDataFromAPI } from '../restapi';
import axios from 'axios';
import {
  DESIGN_CALENDAR
} from '../constants';

const useNewsfeed = (code, offset) => {
  const [postData, setpostData] = useState(null);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    // Now doesn't have a good way to setup master & staging envs yet.
    // There is some work in progress though, but nothing is final as of yet.
    // https://spectrum.chat/zeit/now/env-variables-specifically-for-now-target-production~a02bbe92-bcd8-4093-abf2-a334a0e571f8
    // so over here, we will check for the domain and accordingly point to prod or master domains
    let baseUrl;
    if (import.meta.env.MODE === 'production') {
      baseUrl = `https://connect.socialschools.eu/api/v1/public/newsfeed/widgets/`;
    } else {
      // notice widgets in place of communityposts, since this API is not released to prod yet
      baseUrl = `https://connect.socialschools.eu/api/v1/public/newsfeed/widgets/`;
      // baseUrl = `https://connect-testing.socialschools.eu/api/v1/public/newsfeed/widgets/`;
    }
    const API_URL = `${baseUrl}${code}`;

    const fetchAllData = async (url, params = {}, data) => {
      return await axios
        .get(url, {
          params,
        })
        .then(response => {
          if (response.status === 200) {
            return response.data;
          } else {
            return { results: [] };
          }
        })
        .then(newData => {
          // append new data to existing data
          newData.values = [...data.values, ...newData.values]
          if (newData.values.length < newData.totalCount) {
            // FORT-1113: 
            // NOTE: Override the offset value based on actual length of data received
            offset = data.values.length;
            offset += newData.configuration.limit;
            fetchAllData(url, {offset: offset}, newData);
          }
          // Update the calendar data with new page data
          setpostData(newData);
          return newData;
        })
        .catch(error => {
          return {configuration: null, timeStamp: "", totalCount: 0, values: []}
        });
    }

    if (code !== undefined) {
      GetDataFromAPI(API_URL, { offset: offset })
        .then(data => {
          setpostData(data);
          setloading(false);
          if (data.configuration.design === DESIGN_CALENDAR && data.configuration.limit < data.totalCount) {
            let newOffset = 0;
            if (data.values.length < data.totalCount) {
              newOffset += data.configuration.limit;
              fetchAllData(API_URL, { offset: newOffset }, data)
              .then(newData => {
                setpostData(newData);
              });
            }
          } else {
            setpostData(data);
            setloading(false);
          }
        })
        .catch(error => {
          setpostData([]);
          setloading(false);
        });
    }
    return () => console.log('unmounting...');
  }, [code, offset]);
  return [postData, loading];
};

export default useNewsfeed;
