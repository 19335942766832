import React from 'react';
import GoogleFontLoader from 'react-google-font-loader';
import { RenderPostList } from './containers/RenderPostList';
import { RenderEventList } from './containers/RenderEventList';
import { RenderFullPosts } from './containers/RenderFullPosts';
import { RenderPostCards } from './containers/RenderPostCards';
import { RenderCalendar } from './containers/RenderCalendar';
import Pagination from './components/Pagination/Pagination';
import ErrorBoundary from './utils/index';
import ErrorContainer from './components/ErrorContainer/ErrorContainer';
import {
  TYPE_NEWSFEED,
  TYPE_AGENDAFEED,
  DESIGN_LIST,
  DESIGN_CARD,
  DESIGN_FULL,
  DESIGN_CALENDAR,
  MESSAGES_ALL,
} from './constants';

export default function RenderWidgets({
  data,
  configuration,
  updatePosts,
  totalPosts,
  anon,
}) {
  return (
    <>
      <ErrorBoundary>
        {configuration && (
          <GoogleFontLoader
            fonts={[
              {
                font: configuration.font,
                weights: [400, 700],
              },
            ]}
            subsets={['cyrillic-ext', 'greek']}
          />
        )}
        {configuration &&
          configuration.type === TYPE_AGENDAFEED &&
          configuration.design === DESIGN_LIST && (
            <RenderEventList data={data} configuration={configuration} anon={anon} />
          )}
        {configuration &&
          configuration.type === TYPE_AGENDAFEED &&
          configuration.design === DESIGN_CALENDAR && (
            <RenderCalendar data={data} configuration={configuration} anon={anon} />
          )}
        {configuration &&
          configuration.type === TYPE_NEWSFEED &&
          configuration.design === DESIGN_LIST && (
            <RenderPostList data={data} configuration={configuration} anon={anon} />
          )}
        {configuration &&
          configuration.type === TYPE_NEWSFEED &&
          configuration.design === DESIGN_FULL && (
            <RenderFullPosts data={data} configuration={configuration} anon={anon} />
          )}
        {configuration &&
          configuration.type === TYPE_NEWSFEED &&
          configuration.design === DESIGN_CARD && (
            <RenderPostCards data={data} configuration={configuration} anon={anon} />
          )}
        {configuration && configuration.design !== DESIGN_CALENDAR && configuration.limit !== MESSAGES_ALL && (
          <Pagination
            totalPosts={totalPosts}
            limit={configuration.limit}
            updatePosts={updatePosts}
          />
        )}
      </ErrorBoundary>
      {!configuration && <ErrorContainer />}
    </>
  );
}
