import React from 'react';
import moment from 'moment';
import Moment from 'react-moment';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

// styling
export class RenderEvent extends React.Component {
  render() {
    // borrowed this from the EventTimes lib in the utils. we do not need startEnd Parser
    let { start, end } = this.props.event;
    end = moment(end);
    start = moment(start);
    let multiDay = start.format('L') !== end.format('L');
    let allDay = this.props.event.all_day
      ? this.props.event.all_day
      : this.props.event.allDay;

    if (multiDay && !allDay) {
      // A all day event and that too a multi day is rendered
      // in a different way.
      return (
        // Van vrijdag 21-04-2019 om 08:00 uur tot zaterdag 22-04-2019 om 15:00 uur
        <span>
          <Moment format="ddd DD MMMM YYYY HH:mm uur" locale="nl">
            {this.props.event.start}
          </Moment>{' '}
          <span className="copulative">tot</span>{' '}
          <Moment format="ddd DD MMMM YYYY HH:mm uur" locale="nl">
            {this.props.event.end}
          </Moment>
        </span>
      );
    }
    if (multiDay && allDay) {
      return (
        <span>
          <Moment format="ddd DD MMMM YYYY" locale="nl">
            {this.props.event.start}
          </Moment>{' '}
          <span className="copulative">t/m</span>{' '}
          <Moment format="ddd DD MMMM YYYY" locale="nl">
            {this.props.event.end}
          </Moment>
        </span>
      );
    }
    if (allDay && !multiDay) {
      // Single day event
      // not a multi day event so renders in different way.

      // single day all_day event
      return (
        <span>
          <Moment className="moment-one" format="ddd DD MMMM" locale="nl">
            {this.props.event.start}
          </Moment>{' '}
          de gehele dag
        </span>
      );
    }
    if (!allDay && !multiDay) {
      // single day event with different timings
      return (
        <span>
          <Moment format="ddd DD MMMM YYYY HH:mm uur" locale="nl">
            {this.props.event.start}
          </Moment>{' '}
          <span className="copulative">tot</span>{' '}
          <Moment format="HH:mm uur" locale="nl">
            {this.props.event.end}
          </Moment>
        </span>
      );
    }
  }
}
